import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { CountersService } from './core/services/counters.service';
import { PoiDisplayService } from './core/services/poi-display.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private addMatomo: boolean = false;

  private readonly analyticsUrl = "https://analytics.library.ethz.ch/";
  initMatomoDone: boolean;

  constructor(private poiDisplayService: PoiDisplayService, private countersService: CountersService) {
    if (environment.production) {
      this.addMatomo = true;
    }
  }
  
  async ngOnInit() {
    this.initMatomo();
    this.poiDisplayService.loadPois();
    this.poiDisplayService.setCounters(await this.countersService.get());
  }

  async ngAfterViewInit() {
    this.addMatomoScript();
  }

  async ngOnDestroy() {
    this.destroyMatomo();
  }

  initMatomo() {
    if (!this.addMatomo) {
      return;
    }
    // ################################################################## //
      // ### Start Matomo tag for https://analytics.library.ethz.ch ### //
    // ################################################################## //

    (window as any)._paq = (window as any)._paq || [];
    
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */

    window.addEventListener('click', this.matomoEventListener);
    (window as any)._paq.push(['enableLinkTracking']);
    
    // ################################################################## //
    // ### End Matomo tag for https://analytics.library.ethz.ch ### //
    // ################################################################## //

    this.initMatomoDone = true;
  }

  addMatomoScript() {
    if (!this.addMatomo) {
      return;
    }
    if (!this.initMatomoDone) {
      setTimeout((_: any) => this.addMatomoScript, 100);
      return;
    }
    (window as any)._paq.push(['setTrackerUrl', this.analyticsUrl + 'matomo.php']);
    (window as any)._paq.push(['setSiteId', '15']);
    let matomoScriptBlock = document.createElement('script');
    let firstScriptInDocument = document.getElementsByTagName('script')[0];
    matomoScriptBlock.type='text/javascript'; 
    matomoScriptBlock.async=true; 
    matomoScriptBlock.src= this.analyticsUrl +'matomo.js';
    let parentNode = firstScriptInDocument.parentNode
    if (parentNode) {
      parentNode.insertBefore(matomoScriptBlock,firstScriptInDocument);
    } else {
      document.getRootNode().insertBefore(matomoScriptBlock,firstScriptInDocument)
    }
  }

  destroyMatomo() {
    if (!this.addMatomo) {
      return;
    }
    window.removeEventListener('click', this.matomoEventListener);
  }

  matomoEventListener() {
    (window as any)._paq.push(['setCustomUrl', window.location]);
    (window as any)._paq.push(['setDocumentTitle', document.domain + "/" + window.location]);
    (window as any)._paq.push(['trackPageView']);
  }

}
